import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowsProp,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import {
  MdEdit as EditIcon,
  MdFilterList as FilterListIcon,
  MdSend as SendIcon,
  MdOutlineArrowBack as BackIcon
} from "react-icons/md";
import { IoMdMore, IoMdClose } from "react-icons/io";
import { CiSearch, CiUnlock } from "react-icons/ci";

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from 'moment'
import {
  Box,
  Button,
  Grid,
  TextField,
  Popover,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Modal,
  Typography,
  Autocomplete,
  FormControlLabel,
  Menu,
  Divider,
  Pagination,
  PaginationItem,
  Switch,
  Chip,
  Avatar,
  Snackbar,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import LoadingIndicator from "src/components/LoadingIndicator";
import { validateEmailField, validateRequiredField } from "src/share/utils";
import { useMutation, useQuery } from "react-query";
import {
  createGroupUserFn,
  deleteOrganizationUserFn,
  getOrgGroupsFn,
  getOrgUsersFn,
  getOrganizationsFn,
  importUsersCsvFn,
  resendActivationEmailFn,
  updateGroupUserFn,
  updateGroupUserStatusFn,
  updateRequiredCookiesFn,
} from "src/api";
import { queryClient } from "src/query-client";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { countriesPhoneOptions } from "src/share/constants";
import './GroupsTab.modules.css'
import MoreModal from "./MoreModal";
import { Images } from "src/assets";
import CustomPagination from "src/dashboard/components/DataTable/CustomPagination";
import { getInitialsAndColor } from "src/share/utils/getInitialsAndColor";

const style = {
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
  textAlign: 'center',
};

export function UsersTab() {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [filteredRows, setFilteredRows] = useState<GridRowsProp>([]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [filters, setFilters] = useState<Filter[]>([{ column: "", value: "" }, { column: "", value: "" }, { column: "", value: "" }, { column: "", value: "" }]);
  const [rowCount, setRowCount] = useState(10);
  const [rowGroupId, setRowGroupId] = useState(0)
  const [rowUserId, setRowUserId] = useState(0)
  const { orgId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const location = useLocation()
  const { pathname } = location;
  const {
    data: orgs,
  } = useQuery({
    queryKey: "organizations",
    queryFn: () => getOrganizationsFn(),
    keepPreviousData: true,
  });

  const orgName = orgs?.find(item => item.id == Number(orgId))?.name

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const [groupUserModal, setGroupUserModal] = useState<{
    isOpen: boolean;
    headerText: string;
    groupUser?: EditGroupUserInput;
  }>({ isOpen: false, headerText: "New User" });
  const { isFetching: isGroupsLoading, data: groups } = useQuery({
    queryKey: ["orgGroups", orgId],
    queryFn: () => {
      if (!orgId) return;
      return getOrgGroupsFn({ orgId });
    },
  });
  const { isFetching: isUsersLoading, data: users } = useQuery({
    queryKey: [
      "orgUsers",
      orgId,
      paginationModel,
      Object.fromEntries(searchParams.entries()),
    ],
    queryFn: () => {
      if (!orgId) return;
      return getOrgUsersFn({
        orgId,
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        query: Object.fromEntries(searchParams.entries()),
      });
    },
  });
  const [requiredCookies, setRequiredCookies] = useState(false);

  useEffect(() => {
    if (users?.requiredCookies?.required_cookie !== undefined) {
      setRequiredCookies(users.requiredCookies.required_cookie);
    }
  }, [users]);

  const { mutateAsync: createGroupUser, isLoading: isCreatingGroupUser } =
    useMutation(createGroupUserFn, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "orgUsers" });
        closeGroupUserModal();
      },
    });
  const { mutate: updateGroupUser, isLoading: isUpdatingGroupUser } =
    useMutation(updateGroupUserFn, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "orgUsers" });
        closeGroupUserModal();
        alert("The user was succefully updated");
      },
    });
    const { mutate: updateGroupUserStatus, isLoading: isUpdatingGroupUserStatus } =
    useMutation(updateGroupUserStatusFn, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "orgUsers" });
        closeGroupUserModal();
        alert("The user status succefully updated");
      },
    });
  const { mutate: updateRequiredCookies, isLoading: isUpdatingRequiredCookies } =
    useMutation(updateRequiredCookiesFn, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "orgUsers" });
        alert("The user was succefully updated");
      },
    });
  const { mutate: resendActivationEmail } = useMutation(
    resendActivationEmailFn,
    {
      onSuccess: () => {
        alert("Activation Email Sent");
      },
    }
  );
  const { mutate: deleteOrganizationUser } = useMutation(
    deleteOrganizationUserFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("orgUsers");
        alert("The user was succefully deleted");
      },
    }
  );
  const { mutate: importUsersCsv } = useMutation(importUsersCsvFn, {
    onSuccess: (data) => {
      if (data.errorRows) {
        alert("Error Rows: " + JSON.stringify(data.errorRows));
      }
      if (data.groupUserCreationErrors) {
        alert(
          "Errors when creating users: " +
          JSON.stringify(data.groupUserCreationErrors)
        );
      }
      queryClient.invalidateQueries("orgUsers");
    },
  });

  useEffect(() => {
    if (!users) return;
    setRows(users.data);
    setRowCount(users.meta.total);
  }, [users]);


  const handleCreateClick = () => {
    setGroupUserModal({ isOpen: true, headerText: "Create User" });
  };

  const handleCreateGroupUser = async (groupUser: CreateGroupUserInput) => {
    if (!orgId) return;
    await createGroupUser({
      orgId,
      groupID: groupUser.groupId,
      first_name: groupUser.firstName,
      last_name: groupUser.lastName,
      country_code: groupUser.countryCode,
      phone: groupUser.phone,
      email: groupUser.email,
      is_org_admin: groupUser.isOrgAdmin,
    });
  };


  const handleUpdateGroupUser = async (
    groupUser: Partial<EditGroupUserInput>
  ) => {
    if (!orgId) return;
    updateGroupUser({
      orgId,
      userId: groupUser.userId,
      groupId: groupUser.groupId,
      first_name: groupUser.firstName,
      last_name: groupUser.lastName,
      country_code: groupUser.countryCode,
      phone: groupUser.phone,
      email: groupUser.email,
      is_org_admin: groupUser.isOrgAdmin,
      status: groupUser.status,
    });
  };

  const handleUpdateUserGroupStatus = async(userId: number, status: string, groupID: number) => {
    if (!orgId) return;

    updateGroupUserStatus({
      orgId,
      groupID,
      userId,
      status,
    })
  }

  const handleUpdateRequiredCookies = async (val: boolean) => {
    if (!orgId) return;
    updateRequiredCookies({
      orgId,
      requiredCookie: val
    })
  }

  const handleCancel = () => {
    closeGroupUserModal();
  };

  const closeGroupUserModal = () => {
    setGroupUserModal((prevGroupUserModal) => ({
      ...prevGroupUserModal,
      isOpen: false,
    }));
  };



  const handleFilterChange = (
    index: number,
    field: keyof Filter,
    fieldVal: any,
    value: any
  ) => {

    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      newFilters[index][field] = (value === "All" || value === "") ? "" : fieldVal;
      newFilters[index].value = value === "All" ? "" : value;
      return newFilters;
    });
  };

  const handleChipDelete = (chipValue: string) => {
    const newValue = filters[0].value
      .split(",")
      .filter((value: string) => value !== chipValue)
      .join(",");

    handleFilterChange(0, "column", "groupID", newValue);
  };

  const clearAllFilters = () => {

    const isCurGroupUsers = searchParams.get('isCurGroupUsers')
    const groupID = searchParams.get('groupID');

    console.log('isCurGroupUsers', isCurGroupUsers)

    if (isCurGroupUsers === 'true') {
      const newSearchParams = new URLSearchParams();

      if (groupID) {
        newSearchParams.set('groupID', groupID);
      }
      if (isCurGroupUsers) {
        newSearchParams.set('isCurGroupUsers', isCurGroupUsers);
      }

      setSearchParams(newSearchParams);
    } else {
      setSearchParams({ "": "" });
    }
    setFilters([{ column: "", value: "" }, { column: "", value: "" }, { column: "", value: "" }, { column: "", value: "" }]);
    handleFilterChange(0, "column", "", ""); // Group
    handleFilterChange(1, "column", "", ""); // Creation Date
    handleFilterChange(2, "column", "", ""); // Role
    handleFilterChange(3, "column", "", ""); // Status
  };



  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const handleApplyFilters = () => {
    const query = filters.reduce((currentQuery: any, filter) => {
      currentQuery[filter.column] = filter.value;
      return currentQuery;
    }, {});

    const groupID = searchParams.get('groupID');
    const isCurGroupUsers = searchParams.get('isCurGroupUsers');

    if (query.status === 'Active')
      query.status = 'active'
    else if (query.status === 'In Process')
      query.status = 'reg_link_sent'
    else if (query.status === 'Locked')
      query.status = 'freeze'
    else if (query.status === 'Disabled')
      query.status = 'disable'

    if (groupID && isCurGroupUsers) {
      query.groupID = groupID;
      query.isCurGroupUsers = isCurGroupUsers;
    }

    // set the search query of the current URL
    setSearchParams(query);
  };

  const handleEditClick = async (id: GridRowId, groupID: number) => {

    const row = rows.find((row) => `${row.userId}-${row.groupId}` === `${id}-${groupID}`);

    setGroupUserModal({
      isOpen: true,
      headerText: "Update Group User",
      groupUser: row as any,
    });
  };

  const handleUnlockClick = async (id: number, groupID: number) => {

    await handleUpdateGroupUser({
      userId: id,
      groupId: groupID,
      status: 'active'
    });
  };


  const handleResendActivationEmail = async (
    userId: number,
    groupId: number
  ) => {
    resendActivationEmail({
      userId,
      groupId,
    });
  };

  const handleDeleteClick = async (userId: number, groupId: number) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      deleteOrganizationUser({
        userId,
        groupId,
      });
    }
  };

  const ActionsCell: React.FC<{ row: EditGroupUserInput }> = ({ row }) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton aria-controls="more-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <IoMdMore size={32} />
        </IconButton>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {row.status === "Disabled" && <MenuItem sx={{ padding: '14px' }} onClick={() => { handleUpdateUserGroupStatus(row.userId, 'active', row.groupId); }}>
            <img style={{ marginRight: '12px' }} src={Images.icLock} width="20" height="20" />
            Enable
          </MenuItem>}
          {row.status === "Active" && <MenuItem sx={{ padding: '14px' }} onClick={() => { handleUpdateUserGroupStatus(row.userId, 'disable', row.groupId); }}>
            <img style={{ marginRight: '12px' }} src={Images.icLockUser} width="20" height="20" />
            Disable
          </MenuItem>}
          {row.status === "In Process" &&
            <MenuItem sx={{ padding: '14px' }} onClick={() => { handleResendActivationEmail(row.userId, row.groupId) }}>
              <img style={{ marginRight: '12px' }} src={Images.icSend} width="20" height="20" />
              Send Email
            </MenuItem>}
          <MenuItem sx={{ padding: '14px' }} onClick={() => { handleEditClick(row.userId, row.groupId); }}>
            <img style={{ marginRight: '12px' }} src={Images.icEdit} width="20" height="20" />
            Edit User
          </MenuItem>
          <MenuItem sx={{ padding: '14px' }} onClick={() => { handleDeleteClick(row.userId, row.groupId); }}>
            <img style={{ marginRight: '12px' }} src={Images.icTrash} width="20" height="20" />
            Delete User
          </MenuItem>
        </Menu>
      </>
    );
  };

  const uploadCSV = async (file: File) => {
    if (!orgId) return;
    const formData = new FormData();
    formData.append("user-groups-file", file);
    importUsersCsv({ orgId, file: formData });
  };

  const downloadCSV = () => {
    const csvHeader =
      "groupID,first_name,last_name,country_code,phone,email,is_org_admin";
    const csvData = rows
      .map(
        (row) =>
          row.groupId +
          "," +
          row.firstName +
          "," +
          row.lastName +
          "," +
          row.countryCode +
          "," +
          row.phone +
          "," +
          row.email +
          "," +
          (row.isOrgAdmin ? "true" : "false")
      )
      .join("\n");
    const csv = csvHeader + "\n" + csvData;
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "users.csv";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns: GridColDef[] = [
    {
      field: "userId", headerName: "Id",
      width: 60,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 150,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      renderCell: (params) => {
        let fullname
        if (params.row.firstName && params.row.lastName) {
          fullname = `${String(params.row.firstName.split(' ')[0])} ${String(params.row.lastName.split(' ')[0])}`
        }
        const { initials, color } = getInitialsAndColor(fullname || "User");
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={params.row.image}
              sx={{
                width: '36px',
                height: '36px',
                marginRight: '8px',
                borderRadius: '50%',
                backgroundColor: params.row.image ? 'transparent' : color,
              }}>
                {(!params.row.image) && initials}
              </Avatar>
            {params.row.firstName} {params.row.lastName}
          </div>
        )
      },
    },
    {
      field: "groupId",
      headerName: "Group",
      width: 120,
      type: "singleSelect",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      valueOptions: (groups ?? []).map((group) => ({
        value: group.groupId,
        label: group.groupName,
      })),
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      width: 160,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "email", headerName: "Email", width: 200, headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "createDate", headerName: "Created At", width: 200, headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "isOrgAdmin",
      headerName: "Role",
      width: 100,
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      width: 140,
      renderCell: (params) => {
        const status = params.value as string;
        let backgroundColor, color = '';
        if (status === 'Active') {
          backgroundColor = '#F4FCF1';
          color = '#008E0F';
        } else if (status === 'Locked') {
          backgroundColor = '#FFE7E7';
          color = '#EC1616';
        } else if (status === 'Disabled') {
          backgroundColor = '#F9F8FF';
          color = '#919099';
        } else {
          backgroundColor = '#FFF7E7';
          color = '#ECA316';

        }
        return (
          <div style={{ backgroundColor, color, padding: '5px', borderRadius: '100px' }}>
            {status}
          </div>
        );
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "column-style",
      cellClassName: 'row-style',
      align: 'left',
      headerAlign: "left",
      type: "actions",
      width: 100,
      renderCell: (params) => (
        <ActionsCell row={params.row as EditGroupUserInput} />
      ),
      // getActions: ({ id, row }) => {
      //   return [
      //     <GridActionsCellItem
      //       icon={<IoMdMore size={32} />}
      //       label="More"
      //       onClick={handleEditClick(id)}
      //     />
      //   ]
      // if (row.status === "reg_link_sent") {
      //   return [
      //     <GridActionsCellItem
      //       icon={<EditIcon size={20} />}
      //       label="Edit"
      //       onClick={handleEditClick(id)}
      //     />,
      //     <GridActionsCellItem
      //       icon={<SendIcon size={20} />}
      //       label="Resend Activation Email"
      //       onClick={() =>
      //         handleResendActivationEmail(row.userId, row.groupId)
      //       }
      //     />,
      //     <GridActionsCellItem
      //       icon={
      //         <IconButton aria-label="delete" size="small">
      //           <DeleteIcon fontSize="inherit" />
      //         </IconButton>
      //       }
      //       label="Delete"
      //       onClick={() => handleDeleteClick(row.userId, row.groupId)}
      //     />,
      //   ];
      // }
      // return [
      //   <GridActionsCellItem
      //     icon={<EditIcon size={20} />}
      //     label="Edit"
      //     onClick={handleEditClick(id)}
      //   />,
      // ];
      //},
    },
  ];

  const groupFromParams = searchParams.get('groupID')
  let currentGroup
  if (!groupFromParams) {
    currentGroup = undefined
  } else {
    currentGroup = groupFromParams
  }

  const groupIdFilter = filters.find((filter) => filter.column === "groupID");
  const rowsData = filteredRows.map(row => ({
    ...row,
    phoneNumber: `${row.countryCode} ${row.phone}`,
    status: row.status === 'active' ? 'Active' : row.status === 'freeze' ? 'Locked' : row.status === 'disable' ? 'Disabled' : "In Process",
    createDate: moment(row.createDate).format('DD/MM/YYYY, hh:mm A'),
    isOrgAdmin: row.isOrgAdmin ? 'Org_admin' : 'Sym_user'
  }));

  const groupName = groups?.filter(group => group.groupId == Number(searchParams.get('groupID')))[0]?.groupName
  const navigate = useNavigate()
  const [search, setSearch] = useState("");
  const applyFilter = (data: GridRowsProp) => {
    if (!search) {
      setFilteredRows(data);
      return;
    }
    const filteredData = data.filter((row) =>
      row.firstName.toLowerCase().includes(search.toLowerCase()) || row.lastName.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredRows(filteredData);
  };

  useEffect(() => {
    applyFilter(rows);
  }, [search, rows]);

  // function CustomPagination() {
  //   const apiRef = useGridApiContext();
  //   const page = useGridSelector(apiRef, gridPageSelector);
  //   const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  //   const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  //   const pageCount = Math.ceil(rowCount / pageSize);

  //   return (
  //     <Pagination
  //       page={page + 1}
  //       count={pageCount}
  //       sx={{
  //         '& .MuiPaginationItem-root': {
  //           '&.Mui-selected': {
  //             background: '#303030',
  //             color: 'white',
  //           },
  //         },
  //       }}
  //       renderItem={(props2) => <PaginationItem {...props2} />}
  //       onChange={(event: React.ChangeEvent<unknown>, value: number) =>
  //         apiRef.current.setPage(value - 1)
  //       }
  //     />
  //   );
  // }

  return (
    <>
      <Box sx={{
        height: 600,
        width: 'fit-content',
        '& .column-style': {
          backgroundColor: '#F3F2F7',
          fontWeight: "bold"
        },
        '& .row-style': {
          color: '#111111',
          fontSize: '14px'
        },

      }}>
        <Box className="header-group">

          <div className="product-search">
            <CiSearch size={20} />
            <input
              type="text"
              placeholder="Search user"
              className="products__search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={requiredCookies}
                onChange={(e) => handleUpdateRequiredCookies(e.target.checked)}
              />
            }
            label="Required Cookies"
          />
          <UsersToolbar
            setRows={setRows}
            uploadCSV={uploadCSV}
            filters={filters}
            handleFilterChange={handleFilterChange}
            handleChipDelete={handleChipDelete}
            clearAllFilters={clearAllFilters}
            handleRemoveFilter={handleRemoveFilter}
            handleApplyFilters={handleApplyFilters}
            downloadCSV={downloadCSV}
            handleCreateClick={handleCreateClick}
            groups={groups ?? []}
          />
        </Box>
        <DataGrid
          loading={isUsersLoading}
          columns={columns}
          rows={rowsData}
          getRowId={(row) => `${row.userId}-${row.groupId}`}
          editMode="row"
          pageSizeOptions={[10, 20, 50, 100]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 1 },
            },
          }}
          slots={{
            pagination: CustomPagination
          }}
          sx={{
            fontSize: '14px',
            color: '#111111',
            mt: 2,
            border: '1px solid #DCDBE8',
            borderRadius: '8px',
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "break-spaces",
              fontWeight: "500",
              fontFamily: "Inter",
              lineHeight: 1.2,
            },
            '& .MuiPaginationItem-root.Mui-selected:hover': {
              backgroundColor: '#303030',
              color: 'white',
            },
          }}
          paginationMode="server"
          rowCount={rowCount}
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={handleClose}
        sx={{
          '.MuiSnackbarContent-root': {
            background: '#303030 !important',
            borderRadius: '30px'
          }
        }}
        message="A new user was created successfuly"
      />
      <UserFormModal
        handleCreateGroupUser={handleCreateGroupUser}
        handleUpdateGroupUser={handleUpdateGroupUser}
        handleCancel={handleCancel}
        isOpen={groupUserModal.isOpen}
        headerText={groupUserModal.headerText}
        groupUser={groupUserModal.groupUser}
        groups={groups ?? []}
        isGroupsLoading={isGroupsLoading}
        isSubmitting={isCreatingGroupUser || isUpdatingGroupUser}
        defaultGroupId={
          isNaN(Number(groupIdFilter?.value))
            ? Number(currentGroup)
            : parseInt(groupIdFilter?.value)
        }
      />
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <Box sx={style}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h5"
              component="h4"
            // sx={{ fontWeight: "bold" }}
            >
              Are you sure you want to delete this user?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "30px",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => { handleDeleteClick(rowUserId, rowGroupId) }}
                sx={{
                  borderRadius: "12px",
                  fontSize: "16px"
                }}
              >
                delete
              </Button>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  borderRadius: "12px",
                  fontSize: "16px"
                }}
              >
                cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

interface UsersToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  uploadCSV: (file: File) => void;
  filters: Filter[];
  handleFilterChange: (index: number, field: keyof Filter, fieldVal: any, value: any) => void;
  handleChipDelete: (chipValue: string) => void;
  handleRemoveFilter: (index: number) => void;
  handleApplyFilters: () => void;
  clearAllFilters: () => void;
  downloadCSV: () => void;
  handleCreateClick: () => void;
  groups: Group[];
}


function UsersToolbar({
  uploadCSV,
  filters,
  handleFilterChange,
  handleChipDelete,
  handleRemoveFilter,
  handleApplyFilters,
  clearAllFilters,
  downloadCSV,
  handleCreateClick,
  groups,
}: UsersToolbarProps) {
  const [unfilteredColumns, setUnfilteredColumns] = useState<
    Array<{
      name: string;
      type: string;
      label: string;
      disabled: boolean;
    }>
  >([]);
  const csvFileInputRef = useRef<HTMLInputElement>(null);

  const [filterAnchorEl, setFilterAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [searchParams] = useSearchParams();
  const groupID = searchParams.get('groupID');

  useEffect(() => {
    setUnfilteredColumns(() => {
      return filterColumns.map((col) => {
        const isFiltered =
          filters.find((filter) => filter.column === col.name) !== undefined;
        return { ...col, disabled: isFiltered };
      });
    });
  }, [filters]);

  const filterColumns: FilterColumns = [
    {
      name: "userID",
      type: "number",
      label: "User ID",
    },
    {
      name: "groupID",
      type: "singleSelect",
      label: "Group",
      options: (groups ?? []).map((group) => ({
        value: group.groupId,
        label: group.groupName,
      })),
    },
    {
      name: "status",
      type: "singleSelect",
      label: "Status",
      options: ["Active", "Locked", "In Process", "Disabled"].map(
        (status) => ({
          value: status,
          label: status,
        })
      ),
    },
    {
      name: "group_name",
      type: "string",
      label: "Group Name",
    },
    {
      name: "first_name",
      type: "string",
      label: "First Name",
    },
    {
      name: "last_name",
      type: "string",
      label: "Last Name",
    },
    {
      name: "country_code",
      type: "string",
      label: "Country Code",
    },
    {
      name: "phone",
      type: "number",
      label: "Phone Number",
    },
    {
      name: "email",
      type: "string",
      label: "Email",
    },
    {
      name: "is_org_admin",
      type: "boolean",
      label: "Is Org Admin",
    },
  ];

  const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterAnchorEl(null);
  };

  const handleUploadCSVClick = () => {
    csvFileInputRef.current?.click();
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files === null) return;
    const file = files[0];
    uploadCSV(file);
  };

  const filterOpen = Boolean(filterAnchorEl);
  const isCurGroupUsers = searchParams.get('isCurGroupUsers');
  const renderFilters = () => {


    return (
      <Box sx={{ display: 'flex', flexDirection: "column", gap: '40px', width: '100%' }}>
        <Box sx={{ cursor: "pointer", }} onClick={clearAllFilters}>
          <Typography textAlign="right" sx={{ textDecoration: "underline" }} color="#7694FF">Clear All</Typography>
        </Box>
        {!isCurGroupUsers && (
          <FormControl size="small" margin="none" fullWidth>
            <InputLabel>Group</InputLabel>
            <Select
              fullWidth
              multiple
              label={'Group'}
              displayEmpty
              value={filters[0].value.split(',')}
              renderValue={(selected) => {
                if (selected.length === 1 && selected[0] == "") {
                  return 'All Groups';
                }
                return `${selected.length} selected`;
              }}
              onChange={(event) => {
                const selectedValues = event.target.value as string[];
                let newValue = selectedValues.join(',').replace(/^,/, '');
                if (selectedValues.indexOf("") !== 0 && selectedValues.indexOf("") !== -1) {
                  newValue = "";
                }

                handleFilterChange(
                  0,
                  "column",
                  "groupID",
                  newValue
                );
              }}
            >
              <MenuItem value="">
                All Groups
              </MenuItem>
              {groups.map((col) => (
                <MenuItem
                  value={col.groupId}
                  key={col.groupId}
                >
                  {col.groupName}
                </MenuItem>
              ))}
            </Select>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {filters[0].value.split(",").map((value: any) => {
                if (value != "")
                  return (
                    <Chip
                      deleteIcon={<img width="14" height="14" src={Images.icClose} />}
                      key={value}
                      label={groups.find(option => option.groupId === Number(value))?.groupName}
                      onDelete={() => handleChipDelete(value)}
                      style={{ justifyContent: 'space-between', width: '150px', background: '#E9EEF8', margin: '10px 12px 0 0', paddingRight: '10px' }}
                    />
                  )
              })}
            </div>
          </FormControl>
        )}
        <FormControl size="small" margin="none" fullWidth>
          <InputLabel>Creation Date</InputLabel>
          <Select
            fullWidth
            displayEmpty
            label={'Creation Date'}
            value={filters[1].value == "" ? "All" : filters[1].value}
            onChange={(event) => {
              handleFilterChange(
                1,
                "column",
                "createData",
                event.target.value as string
              );
            }}
          >
            {[{ value: 'All', label: "All" }, { value: 'today', label: "Today" }, { value: 'yesterday', label: "Yesterday" }, { value: 'lastWeek', label: "Last Week" }, { value: 'lastMonth', label: "Last Month" }].map((col, index) => (
              <MenuItem
                value={col.value}
                key={index}
              >
                {col.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" margin="none" fullWidth>
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            fullWidth
            displayEmpty
            label="Role"
            value={filters[2].value == "" ? "All" : filters[2].value}
            onChange={(event) => {
              handleFilterChange(
                2,
                "column",
                "is_org_admin",
                event.target.value as string
              );
            }}
          >
            {["All", "Sym_user", "Org_admin"].map((col, index) => (
              <MenuItem
                value={col}
                key={index}
              >
                {col}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" margin="none" fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            fullWidth
            label="Status"
            value={filters[3].value == "" ? "All" : filters[3].value}
            onChange={(event) => {
              handleFilterChange(
                3,
                "column",
                "status",
                event.target.value as string
              );
            }}
          >
            {["All", "Active", "Locked", "In Process", "Disabled"].map((col, index) => (
              <MenuItem
                value={col}
                key={index}
              >
                {col}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ gap: '17px', marginBottom: 1, display: "flex", flexDirection: 'row-reverse' }}>
      <IconButton aria-controls="more-menu" aria-haspopup="true" onClick={handleMenuClick}>
        <IoMdMore size={16} />
      </IconButton>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem sx={{ padding: '14px' }} onClick={() => { handleMenuClose(); handleUploadCSVClick() }}>
          <img style={{ marginRight: '12px' }} src={Images.icUpload} width="20" height="20" />
          Upload CSV
        </MenuItem>
        <MenuItem sx={{ padding: '14px' }} onClick={() => { handleMenuClose(); downloadCSV() }}>
          <img style={{ marginRight: '12px', transform: 'rotate(180deg)' }} src={Images.icUpload} width="20" height="20" />
          Download CSV
        </MenuItem>

      </Menu>
      <Button
        sx={{
          "&:hover": { backgroundColor: "#111111" },
          backgroundColor: "#111111"
        }}
        className="button-group" variant="contained" onClick={handleCreateClick}>
        New User
      </Button>
      <input
        type="file"
        accept="text/csv"
        ref={csvFileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />
      <Button
        startIcon={<FilterListIcon size={20} />}
        sx={{ color: '#303030', marginLeft: 1 }}
        onClick={handleOpenFilter}
      >
        Filter
      </Button>

      <Modal open={filterOpen}>
        <Box
          sx={{
            width: '35%',
            backgroundColor: "white",
            position: "absolute",
            height: '100%',
            top: 0,
            right: 0,
          }}
        >
          <Box className="header-modal">
            <Typography variant="h6" component="h2">
              Filter
            </Typography>
            <IoMdClose onClick={handleCloseFilter} size={24} />
          </Box>

          <Box sx={{ width: "100%", padding: '60px' }}>
            <Grid container spacing={2}>
              {renderFilters()}
              <Grid item xs={12}>
                <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
                  <Divider sx={{ background: '#DCDBE8' }} />
                  <Box sx={{ padding: '12px 60px', justifyContent: 'space-between', display: 'flex' }}>
                    <Button
                      variant="text"
                      onClick={handleCloseFilter}
                      sx={{ color: '#303030' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        width: '97px !important',
                        "&:hover": { backgroundColor: "#111111" },
                        backgroundColor: "#111111",
                      }}
                      className="button-group"
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        handleApplyFilters()
                        handleCloseFilter()
                      }}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

type Filter = {
  column: string;
  value: any;
};

type FilterColumn = {
  name: string;
  label: string;
};

type NumberFilterColumn = FilterColumn & {
  type: "number";
};

type SingleSelectFilterColumn = FilterColumn & {
  type: "singleSelect";
  options: Array<{
    value: number | string;
    label: string;
  }>;
};

type StringFilterColumn = FilterColumn & {
  type: "string";
};

type BooleanFilterColumn = FilterColumn & {
  type: "boolean";
};

type FilterColumns = Array<
  | NumberFilterColumn
  | SingleSelectFilterColumn
  | StringFilterColumn
  | BooleanFilterColumn
>;

type Group = {
  groupId: number;
  groupName: string;
};

interface UserFormModalProps {
  isOpen: boolean;
  handleCreateGroupUser: (group: CreateGroupUserInput) => Promise<void> | void;
  handleUpdateGroupUser: (
    group: Partial<EditGroupUserInput>
  ) => Promise<void> | void;
  headerText: string;
  handleCancel: () => void;
  groups: Group[];
  defaultGroupId?: number;
  groupUser?: EditGroupUserInput;
  isGroupsLoading: boolean;
  isSubmitting: boolean;
}

function UserFormModal({
  isOpen,
  handleCreateGroupUser,
  handleUpdateGroupUser,
  headerText,
  groupUser,
  handleCancel,
  groups,
  defaultGroupId,
  isGroupsLoading,
  isSubmitting,
}: UserFormModalProps) {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit: formHandleSubmit,
    getValues,
    setValue,
    reset,
    control,
  } = useForm<CreateGroupUserInput | EditGroupUserInput>({
    defaultValues: groupUser,
  });

  useEffect(() => {
    reset(
      groupUser ?? {
        userId: undefined,
        groupId: defaultGroupId,
        email: "",
        firstName: "",
        lastName: "",
        countryCode: undefined,
        phone: "",
        isOrgAdmin: false,
      }
    );
  }, [reset, groupUser, defaultGroupId, isOpen]);

  const sendSubmit = async () => {
    if (!isValid) {
      return;
    }
    const isNew = groupUser === undefined;
    const newGroup = getValues();
    if (isNew) {
      try {
        await handleCreateGroupUser(newGroup);
        reset({
          groupId: newGroup.groupId,
          countryCode: newGroup.countryCode,
          isOrgAdmin: false,
          email: "",
          firstName: "",
          lastName: "",
          phone: "",
        });
      } catch (err) {
        return;
      }
      return;
    }
    // Get the different properties between newGroup and groupUser
    // and only send those to the update function
    const updatedProperties = (
      Object.keys(newGroup) as (keyof typeof newGroup)[]
    ).filter((key) => newGroup[key] !== groupUser[key]);
    const updatedGroupUser = updatedProperties.reduce((currentObj, key) => {
      currentObj[key] = newGroup[key];
      return currentObj;
    }, {} as any);

    if (updatedGroupUser.status === 'Active')
      updatedGroupUser.status = 'active'
    else if (updatedGroupUser.status === 'In Process')
      updatedGroupUser.status = 'reg_link_sent'
    else if (updatedGroupUser.status === 'Locked')
      updatedGroupUser.status = 'freeze'
    else if (updatedGroupUser.status === 'Disable')
      updatedGroupUser.status = 'disable'

    await handleUpdateGroupUser({
      ...updatedGroupUser,
      userId: groupUser.userId,
      groupId: groupUser.groupId,
    });
  };

  const groupOptions = groups.map((group) => ({
    value: group.groupId,
    label: group.groupName,
  }));

  const getStatusList = (currentStatus: string) => {
    const statusList = new Set(["disable"]);
    statusList.add(currentStatus);
    if (currentStatus === "active") {
      statusList.add("Locked");
    }
    if (currentStatus !== "reg_link_sent" && currentStatus !== 'active') {
      statusList.add("Active");
    }
    if (currentStatus === "disable") {
      statusList.add("In Process");
    }
    return Array.from(statusList);
  };

  if (isGroupsLoading) {
    return (
      <Modal open={isOpen}>
        <div>
          <LoadingIndicator variant="fullWidth" />
        </div>
      </Modal>
    );
  }

  const statusMapping: Record<string, string> = {
    active: "Active",
    reg_link_sent: "In Process",
    disable: "Disabled",
    freeze: "Locked"
  };

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          width: '35%',
          backgroundColor: "white",
          position: "absolute",
          height: '100%',
          top: 0,
          right: 0,
        }}
      >
        <Box className="header-modal">
          <Typography variant="h6" component="h2">
            {headerText}
          </Typography>
          <IoMdClose onClick={handleCancel} size={24} />
        </Box>
        <form style={{ padding: '60px', width: '100%' }} onSubmit={formHandleSubmit(sendSubmit)}>
          {groupUser && (
            <>
              <Typography variant="body1">
                Group Name:{" "}
                {
                  groups.find((group) => group.groupId === groupUser.groupId)
                    ?.groupName
                }
              </Typography>
              <Typography variant="body1">
                User ID: {groupUser.userId}
              </Typography>
            </>
          )}
          {!groupUser && (
            <>
              <InputLabel style={{ marginTop: 16 }}>Group</InputLabel>
              <Controller
                name="groupId"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onBlur, onChange, ref } }) => (
                  <Autocomplete
                    options={groupOptions}
                    getOptionLabel={(option) => option.label}
                    ref={ref}
                    onChange={(_, newValue) => {
                      onChange(newValue?.value);
                    }}
                    defaultValue={groupOptions.find(
                      (group) => group.value === getValues("groupId")
                    )}
                    onBlur={onBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Enter group name"
                        error={errors.groupId ? true : false}
                        helperText={
                          errors.groupId ? errors.groupId.message : null
                        }
                      />
                    )}
                  />
                )}
              />
            </>
          )}
          <InputLabel style={{ marginTop: 16 }}>First Name</InputLabel>
          <TextField
            {...register("firstName", {
              required: true,
              validate: validateRequiredField,
            })}
            helperText={errors.firstName && errors.firstName.message}
            error={!!errors.firstName}
            placeholder="Enter first name"
            fullWidth
            defaultValue={getValues("firstName")}
          />
          <InputLabel style={{ marginTop: 16 }}>Last Name</InputLabel>
          <TextField
            {...register("lastName", {
              required: true,
              validate: validateRequiredField,
            })}
            helperText={errors.lastName && errors.lastName.message}
            error={!!errors.lastName}
            placeholder="Enter last name"
            fullWidth
            defaultValue={getValues("lastName")}
          />
          <InputLabel style={{ marginTop: 16 }}>Country Code</InputLabel>
          <Controller
            name="countryCode"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onBlur, onChange, ref } }) => (
              <Autocomplete
                options={countriesPhoneOptions}
                getOptionLabel={(option) => option.label}
                ref={ref}
                value={countriesPhoneOptions.find(
                  (option) =>
                    option.value === value || `+${option.value}` === value
                )}
                onChange={(_, newValue) => {
                  onChange(newValue?.value);
                }}
                onBlur={onBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose country"
                    error={errors.countryCode ? true : false}
                    helperText={
                      errors.countryCode ? errors.countryCode.message : null
                    }
                  />
                )}
              />
            )}
          />
          <InputLabel style={{ marginTop: 16 }}>Phone</InputLabel>
          <TextField
            {...register("phone", { required: true })}
            helperText={errors.phone && errors.phone.message}
            error={!!errors.phone}
            placeholder="Enter phone number"
            fullWidth
            defaultValue={getValues("phone")}
          />
          <InputLabel style={{ marginTop: 16 }}>Email</InputLabel>
          <TextField
            {...register("email", {
              required: true,
              validate: validateEmailField,
            })}
            helperText={errors.email && errors.email.message}
            error={!!errors.email}
            placeholder="Enter Email"
            fullWidth
            defaultValue={getValues("email")}
          />
          {groupUser && (
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                {...register("status", { required: true })}
                label="Status"
                value={getValues("status") || ""}
              >
                {getStatusList(groupUser.status).map((statusName) => {
                  // Get the display name from the mapping
                  const displayName = statusMapping[statusName.toLowerCase()];
                  return (
                    <MenuItem key={statusName} value={statusName}>
                      {displayName || statusName} {/* Use the display name if found, otherwise fallback to statusName */}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          <Controller
            name="isOrgAdmin"
            control={control}
            rules={{ required: false }}
            render={({ field: { value, onChange, ref } }) => (
              <Box sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Typography color="#797880" variant="body1">Is Organization Admin</Typography>
                <Switch checked={value} onChange={(e) => onChange(Boolean(e.target.checked))} />
              </Box>
            )}
          />
          <Box sx={{ left: 0, width: '100%', bottom: 0, position: 'absolute' }}>
            <Divider sx={{ background: '#DCDBE8' }} />
            <Box sx={{ padding: '12px 60px', justifyContent: 'space-between', display: 'flex' }}>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{ color: '#303030' }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: '97px !important',
                  "&:hover": { backgroundColor: "#111111" },
                  backgroundColor: "#111111",
                }}
                variant="contained"
                type="submit"
                disabled={!isDirty || isSubmitting}
              >
                {groupUser ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}

type CreateGroupUserInput = {
  groupId: number;
  firstName: string;
  lastName: string;
  countryCode: string;
  phone: string | number;
  email: string;
  isOrgAdmin: boolean;
};

type EditGroupUserInput = CreateGroupUserInput & {
  userId: number;
  status: string;
};

export default UsersTab;
