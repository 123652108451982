import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Dialog,
  Tooltip,
  Typography,
} from "@mui/material";
import { parsePhoneNumber } from "libphonenumber-js";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import {
  Navigate,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { io, Socket } from "socket.io-client";
import { getSessionAccessFn, loginWithEmailFn, loginWithPhoneFn } from "src/api";
import { Images } from "src/assets";
import { BackButton, SmallLogo } from "src/components/SymanisLogo";
import { env } from "src/env";
import { useAuth } from "src/providers/AuthProvider";
import { regExEmail, regExPhone } from "src/share/constants";
import { keyframes } from '@emotion/react';
import { DEFAULT_USER_AGENT, isMobile } from "src/share/utils/checkUserAgent";

const shake = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateY(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateY(10px);
  }
`;


const ContentWrapper: FC<BoxProps & { open: boolean, onClose?: () => void }> = ({ children, open, ...rest }) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "white",
          backgroundImage: `url(${Images.icMatrixBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          alignItems: 'center',
          display: 'flex',
          borderRadius: 24,
          height: 'auto',
          width: 'auto',
          padding: "32px",
          boxShadow: "none",
        },
      }}
      open={open}
    >
      {children}
    </Dialog>
  );
};

const MatrixRenderer: React.FC<MatrixRendererProps> = ({ tempMatrix, open, handleLogin, children, onShowLettersOnly }) => {
  const handleLogoClick = () => {
    onShowLettersOnly((prev) => !prev);
    setBlindMode(!blindMode)
  };
  const [blindMode, setBlindMode] = useState(false)
  const isSymbolAvailable = !!tempMatrix.find(row => row.find(cell => cell.symbol));
  return (
    <ContentWrapper open={open}>
      <Box sx={{ width: '100%', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: '4px' }}>
          <Button sx={{ padding: '0 0 0 0' }} onClick={handleLogin}>
            <BackButton width="24" height="24" />
            <Typography>Login</Typography>
          </Button>
        </Box>
        <SmallLogo />
        {isSymbolAvailable ? (
          <Tooltip
            title={blindMode ? "Disable Colorblind Mode" : "Enable Colorblind Mode"}
            arrow
            PopperProps={{
              sx: {
                fontSize: '14px',
                fontWeight: '500',
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'white',
                  padding: '8px',
                  color: 'black',
                  borderRadius: '8px'
                },
                '& .MuiTooltip-arrow': {
                  color: 'white',
                },
              },
            }}
          >
            <Box
              sx={{
                ml: '25px',
                cursor: isSymbolAvailable ? 'pointer' : 'default',
              }}
              onClick={isSymbolAvailable ? handleLogoClick : undefined}
            >
              {blindMode ? (
                <img src={`${Images.icActiveEye}`} width={40} height={36} alt="icActiveEye" />
              ) : (
                <img src={`${Images.icInactiveEye}`} width={40} height={36} alt="icInactiveEye" />
              )}
            </Box>
          </Tooltip>
        ) : (
          blindMode ? (
            <img src={`${Images.icActiveEye}`} width={40} height={36} alt="icActiveEye" />
          ) : (
            <img src={`${Images.icInactiveEye}`} width={40} height={36} alt="icInactiveEye" />
          )
        )}
      </Box>
      <Typography sx={{ margin: '40px 0 20px 0' }} textAlign="center">
        Identify the location of your secret<br />symbol & its random background color
      </Typography>
      {children}
    </ContentWrapper>
  );
};



function AuthPage({ ttr, sessionId, open, onClose, email, }: { ttr: string, sessionId: string, open: boolean, onClose: () => void, email: string, }) {
  const [clientSocket, setClientSocket] = useState<Socket | null>(null);
  const [sessionState, setSessionState] = useState<SessionState>();
  const [session, setSession] = useState("")
  const [isError, setIsError] = useState(false)
  const [tempMatrix, setTempMatrix] = useState<SymbolMatrixCell[][]>([])
  const [showLettersOnly, setShowLettersOnly] = useState(false);
  const [searchParams] = useSearchParams();
  const { login } = useAuth();
  const navigate = useNavigate();
  const userAgent = navigator.userAgent;
  const isMobileWebConnenction = useMemo(() => isMobile(userAgent) ? true : false, [userAgent]);

  const retrySession = useCallback((email: string) => {
    if (regExEmail.test(email)) {
      submitEmailLogin({ email: email.toLowerCase(), requestSource: window.location.href, sourceOrg: searchParams.get("org") });
    } else if (regExPhone.test(email)) {
      const parsed = parsePhoneNumber(email);
      if (parsed) {
        const { countryCallingCode, nationalNumber } = parsed;
        submitPhoneLogin({
          requestSource: window.location.href,
          sourceOrg: searchParams.get("org"),
          countryCode: countryCallingCode,
          phone: nationalNumber,
        });
      }
    }
  }, [searchParams]);

  const { mutate: submitEmailLogin } = useMutation(loginWithEmailFn, {
    onSuccess: (data) => {
      const sessionId = data.sessionId
      setSession(sessionId)
    },
    onError: (error: any) => {
      setIsError(true)
      setSession('')
      alert(error.response.data.message);
    },
  }
  )
  const { mutate: submitPhoneLogin } = useMutation(loginWithPhoneFn, {
    onSuccess: (data) => {
      const sessionId = String(data.sessionId)
      setSession(sessionId)
    },
    onError: (error: any) => {
      setSession('')
      alert(error.response.data.message);
    },
  })

  const { mutate: getSessionAccess, isLoading: isGettingAccess } = useMutation(
    getSessionAccessFn,
    {
      onSuccess: (data) => {
        login(data.token, (user) => {
          const continueURL = searchParams.get("continue");
          const userEmail = searchParams.get("userEmail");
          const acsUrl = searchParams.get("acsUrl")
          const issuer = searchParams.get("issuer")
          const samlRequest = searchParams.get("SAMLRequest")
          const relayState = searchParams.get('RelayState')
          const sessionEmailAddress = sessionStorage.getItem("last_user") !== undefined ? sessionStorage.getItem("last_user") : null;
          if(sessionEmailAddress){
            localStorage.setItem("last_user", sessionEmailAddress);
            sessionStorage.removeItem("last_user");
          }
          if (continueURL) {
            const url = new URL(continueURL)
            if (acsUrl) {
              url.searchParams.set('acsUrl', acsUrl)
            }
            if (issuer) {
              url.searchParams.set('issuer', issuer)
            }
            if (samlRequest) {
              url.searchParams.set('SAMLRequest', samlRequest)
            }
            if (relayState) {
              url.searchParams.set('RelayState', relayState)
            }
            url.searchParams.set('userEmail', userEmail || '')
            window.location.href = url.toString()
            return
          }

          navigate(`/${user.id}/personal/settings/profile`);
        });
      },
    }
  );

  const handleLogin = () => {
    clientSocket?.disconnect()
    onClose()
  };

  const query: Query = {
    sessionId: session ? session : sessionId,
    ttr: ttr,
  };

  if (isMobileWebConnenction) {
    query["user-agent"] = DEFAULT_USER_AGENT;
  }

  useEffect(() => {
    if (!sessionId) {
      return;
    }
    const socket = io(env.API_URL_WS + "/auth", {
      query: query,
      transports: ["polling", "websocket"],
      autoConnect: false,
    });

    window.addEventListener("popstate", () => {
      socket.disconnect();
    });

    socket.on("connect", () => {
      setSessionState({ _tag: "new" });
    });
    socket.on("disconnect", (reason) => {
      // Do not fail the session if the client disconnect itself
      if (reason === "io client disconnect") {
        console.log(reason, "reson");
        return;
      }
      setSessionState({ _tag: "disconnected" });
    });
    socket.on("sessionTimeout", () => {
      setSessionState({ _tag: "timeout" });
    });
    socket.on("sessionCancelled", () => {
      setSessionState({ _tag: "cancelled" });
    });
    socket.on("notTheSameCountries", () => {
      console.log('notTheSameCountries emit')
      alert('The connection cannot be set because the devices are located in different countries')
      setSessionState({ _tag: "notTheSameCountries" });
    });
    socket.on("sessionStarted", (newMatrix) => {
      setSessionState({ _tag: "in-progress", matrix: newMatrix });
    });
    socket.on("updateMatrix", (updatedMatrix) => {
      setSessionState({ _tag: "in-progress", matrix: updatedMatrix });
      setTempMatrix(updatedMatrix)
    });
    socket.on("sessionFailed", () => {
      setSessionState({ _tag: "failed" });
      socket.disconnect();
    });
    socket.on("sessionSuccess", async (authToken) => {
      setSessionState({ _tag: "success" });
      socket.disconnect();
      getSessionAccess({ token: authToken });
    });

    setClientSocket(socket);
  }, [sessionId, session, submitEmailLogin]);

  useEffect(() => {
    if (!clientSocket) {
      return;
    }
    clientSocket.connect();
  }, [clientSocket]);

  useEffect(() => {
    if (sessionState?._tag === "failed") {
      setTimeout(() => {
        retrySession(email);
      }, 1000);
    }
  }, [sessionState, email]);

  if (!sessionId) {
    return <Navigate to="/" />;
  }

  if (!sessionState || !clientSocket) {
    return (
      <ContentWrapper open={open} onClose={onClose}>
        <Typography>Connecting...</Typography>
      </ContentWrapper>
    );
  }

  const goBack = () => {
    onClose()
  };

  if (sessionState._tag === "new") {
    return (
      !session ? (
        <ContentWrapper open={open} onClose={onClose}>
          <Typography>Open mobile to authenticate...</Typography>
        </ContentWrapper>
      ) : <MatrixRenderer tempMatrix={tempMatrix} open={open} handleLogin={handleLogin} onShowLettersOnly={setShowLettersOnly}>
        {tempMatrix?.map((row, rowIndex) => (
          <div key={rowIndex} style={{ display: "flex" }}>
            {row.map((cell, cellIndex) => (
              <Box
                component="span"
                key={cellIndex}
                sx={{
                  backgroundColor: showLettersOnly ? '#FFFFFF' : cell.color,
                  border: '2px solid rgba(0,0,0,0.16)',
                  aspectRatio: '1',
                  width: '80px',
                  margin: '0 12px 16px 12px',
                  height: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '24px',
                  padding: '10px',
                  overflow: 'hidden',
                  animation: `${shake} 1s ease-in-out 0s 1`,
                }}
              >
              </Box>
            ))}
          </div>
        ))}
        <Typography sx={{ color: 'red' }}>Please try again!</Typography>
      </MatrixRenderer>
    );
  }

  if (isError) {
    return (
      <ContentWrapper open={open} onClose={onClose}>
        <Typography>Session has ended</Typography>
        <Button onClick={goBack} variant="contained">
          Go to Login
        </Button>
      </ContentWrapper>
    )
  }

  if (sessionState._tag === "failed") {
    return (
      <MatrixRenderer tempMatrix={tempMatrix} open={open} handleLogin={handleLogin} onShowLettersOnly={setShowLettersOnly}>
        {tempMatrix?.map((row, rowIndex) => (
          <div key={rowIndex} style={{ display: "flex" }}>
            {row.map((cell, cellIndex) => (
              <Box
                component="span"
                key={cellIndex}
                sx={{
                  backgroundColor: showLettersOnly ? '#FFFFFF' : cell.color,
                  border: '2px solid rgba(0,0,0,0.16)',
                  aspectRatio: '1',
                  width: '80px',
                  margin: '0 12px 16px 12px',
                  height: '80px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '24px',
                  padding: '10px',
                  overflow: 'hidden',
                  animation: `${shake} 1s ease-in-out 0s 1`,
                }}
              >
              </Box>
            ))}
          </div>
        ))}
        <Typography sx={{ color: 'red', }}>Please try again!</Typography>

      </MatrixRenderer>
    );
  }

  if (sessionState._tag === "disconnected") {
    return (
      <ContentWrapper open={open} onClose={onClose}>
        <Typography>Session has ended</Typography>
        <Button onClick={goBack} variant="contained">
          Go to Login
        </Button>
      </ContentWrapper>
    );
  }

  if (sessionState._tag === "timeout") {
    return (
      <ContentWrapper open={open} onClose={onClose}>
        <Typography>Session timed out</Typography>
        <Button onClick={goBack} variant="contained">
          Go to Login
        </Button>
      </ContentWrapper>
    );
  }

  if (sessionState._tag === "cancelled") {
    return (
      <ContentWrapper open={open} onClose={onClose}>
        <Typography>Session cancelled</Typography>
        <Button onClick={goBack} variant="contained">
          Go to Login
        </Button>
      </ContentWrapper>
    );
  }

  if (sessionState._tag === "success") {
    if (isGettingAccess) {
      return (
        <ContentWrapper open={open} onClose={onClose}>
          <Typography>
            Getting Access <CircularProgress />
          </Typography>
        </ContentWrapper>
      );
    }
    return (
      <ContentWrapper open={open} onClose={onClose}>
        <Typography>Session success</Typography>
      </ContentWrapper>
    );
  }

  if (sessionState._tag === "in-progress") {
    return (
      <MatrixRenderer tempMatrix={sessionState.matrix} open={open} handleLogin={handleLogin} onShowLettersOnly={setShowLettersOnly}>
        {sessionState.matrix.map((row, rowIndex) => (
          <div key={rowIndex} style={{ display: "flex" }}>
            {row.map((cell, cellIndex) => (
              <Box
                component="span"
                key={cellIndex}
                sx={{
                  backgroundColor: showLettersOnly ? '#FFFFFF' : cell.color,
                  border: '2px solid rgba(0,0,0,0.16)',
                  aspectRatio: '1',
                  width: '80px',
                  margin: '0 12px 16px 12px',
                  height: '80px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '24px',
                  padding: '10px',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                {showLettersOnly && (
                  <Typography
                    sx={{
                      fontSize: cell.symbol ? '16px' : '32px',
                      fontWeight: 500,
                      textAlign: 'center',
                      lineHeight: '11px'
                    }}
                  >
                    {cell.letter}
                  </Typography>
                )}

                {cell.symbol ? (
                  <img
                    src={cell.symbol}
                    alt=""
                    style={{ maxWidth: '100%', aspectRatio: '1' }}
                  />
                ) : null}
              </Box>
            ))}
          </div>
        ))}
      </MatrixRenderer>
    );
  }

  return null;
}

type SessionState =
  | NewSession
  | InProgressSession
  | TimeoutSession
  | CancelledSession
  | DisconnectedSession
  | FailedSession
  | SuccessSession
  | CheckCountries;

type FailedSession = {
  _tag: "failed";
  matrix?: SymbolMatrixCell[][];
};

type SuccessSession = {
  _tag: "success";
};

type NewSession = {
  _tag: "new";
};

type TimeoutSession = {
  _tag: "timeout";
};

type CheckCountries = {
  _tag: "notTheSameCountries";
};

type CancelledSession = {
  _tag: "cancelled";
};

type InProgressSession = {
  _tag: "in-progress";
  matrix: SymbolMatrixCell[][];
};

type DisconnectedSession = {
  _tag: "disconnected";
};

type SymbolMatrixCell = {
  letter?: string;
  symbol?: string;
  color: string;
};

interface MatrixRendererProps {
  tempMatrix: SymbolMatrixCell[][];
  open: boolean;
  handleLogin: () => void;
  children: React.ReactNode;
  onShowLettersOnly: React.Dispatch<React.SetStateAction<boolean>>;
}
interface Query {
  ttr: string;
  sessionId: string;
  "user-agent"?: string;
}

export default AuthPage;
