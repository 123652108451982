import { Avatar, Box, Button, Modal, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { deleteUserImageFn, updateUserImageFn } from "src/api";
import { Images } from "src/assets";
import { useAuth } from "src/providers/AuthProvider";
import styles from "./UpdateUserImageForm.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { getInitialsAndColor } from "src/share/utils/getInitialsAndColor";
import { Demo } from "./Demo";

type UpdateUserImageFormInputs = {
  image: File;
};

interface UpdateUserImageFormProps {
  fullname: string
  imageURL?: string | null;
  onSubmit: () => void;
}

function UpdateUserImageForm({ imageURL, onSubmit, fullname }: UpdateUserImageFormProps) {
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdateUserImageFormInputs>();
  const [isDirty, setIsDirty] = useState(false);
  const [image, setImage] = useState<File | Blob | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const location = useLocation();

  const { mutate: updateUserImage, isLoading: isSubmitting } = useMutation(
    updateUserImageFn,
    {
      onSuccess: () => {
        onSubmit();
        setIsDirty(false);
        // alert("User image updated successfully");
      },
    }
  );

  const { mutate: deleteUserImage } = useMutation(
    deleteUserImageFn,
    {
      onSuccess: () => {
        onSubmit();
      },
    }
  );

  const submitUpdateUserImageForm = async () => {
    console.log(isValid);

    if (!user) return;
    const formData = new FormData();
    formData.append("image", image as File);
    updateUserImage({ userId: user.id, imageFile: formData });
  };
  const remove = () => {
    if (!user) return;
    setImage(null)
    deleteUserImage(user.id)
  }
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (image !== null && isDirty) {
      submitUpdateUserImageForm();
    }
  }, [image, isDirty]);

  const { initials, color } = getInitialsAndColor(fullname || "User");
  const handleSaveCroppedImage = (croppedImageBlob: Blob) => {
    if (!user) return;

    const formData = new FormData();
    formData.append("image", croppedImageBlob as File);
    updateUserImage({ userId: user.id, imageFile: formData });
    setImage(croppedImageBlob)
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <form>
        <Box
          sx={{
            margin: "0px 80px 0px 5px",
            width: 180,
            height: 180,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            top: 90,
            zIndex: 0,
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Avatar
              src={(image && URL.createObjectURL(image)) || imageURL || ""}
              sx={{
                width: 180,
                height: 180,
                // border: "3.2px solid white",
                position: "abolute",
                color: '#36454F',
                fontSize: 50,
                backgroundColor: imageURL ? 'transparent' : color,
              }}
              alt="avatar"
            >
              {(!image && !imageURL) && initials}
            </Avatar>
          </Box>

          {location.pathname.includes('profile') && (
            <Box sx={{
              alignItems: 'center',
              marginTop: '23px',
              width: '150px',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <label htmlFor="imageInput">
                <Button
                  sx={{
                    "&:hover": { backgroundColor: "white", border: '1px solid #303030' },
                    color: '#111111',
                    border: '1px solid #303030',
                    borderRadius: '12px',
                    fontSize: '14px',
                    position: 'relative',
                    overflow: 'hidden',
                    cursor: 'pointer'
                  }}
                  variant="outlined"
                >
                  Replace image
                </Button>
                <input
                    type="file"
                    id="imageInput"
                    accept="image/*"
                    style={{
                      opacity: 0,
                      width: 130,
                      position: 'absolute',
                      top: 205,
                      left: 23,
                    }}
                    {...register("image", { required: true })}
                    onChange={(e) => {
                      onSelectFile(e)
                      handleOpenModal()
                    }}
                  />
              </label>
              <Button
                onClick={() => remove()}
                sx={{
                  "&:hover": { backgroundColor: "white" },
                  color: '#666666',
                  fontSize: '14px',
                  marginTop: "16px"
                }}
              >
                Remove image
              </Button>
            </Box>
          )}
        </Box>
        {errors.image && <span>This field is required</span>}
      </form>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #fff',
          boxShadow: 24,
          // p: 2,
          paddingX: 4,
          paddingY: 2,
          borderRadius: 3,
          textAlign: 'center',
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: '8px', fontWeight: "bold" }}>
            Edit image position
          </Typography>
          <Demo img={imgSrc} onSave={handleSaveCroppedImage} handleCloseModal={handleCloseModal} />
          <Box sx={{ alignItems: 'center', marginTop: '23px', width: '150px', display: 'flex', gap: '50px' }}>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default UpdateUserImageForm;
